import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Logo from './images/luxvtlogo.png';
import useStyles from './styles';

const Header = () => {
  const classes = useStyles();

  return (
    <AppBar position="absolute" color="default" className={classes.appBar}>
      <Toolbar>
        <img src={Logo} alt="logo" />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
