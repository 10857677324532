import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  appBar: {
    position: 'relative',
    '& img': {
      height: 40
    }
  }
}));
