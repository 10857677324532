import React, { useState } from 'react';
import { Grid, Typography, Button, CircularProgress } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import useStyles from './styles';
import axios from 'axios';

// const baseURL = 'http://localhost:3001';
const baseURL = '';

const Form = ({ address, setSubmitted, url }) => {
  const classes = useStyles();
  const [isSubmitting, setSubmitting] = useState(false);

  const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

  const [error, toggleError] = useState({
    name: false,
    email: false,
    submission: false
  });

  const [touched, setTouched] = useState({
    name: false,
    email: false
  });

  const [values, changeValue] = useState({
    name: '',
    email: ''
  });

  const handleChange = e => {
    const { name, value } = e.target;
    if (name === 'email' && validateEmail(value)) {
      toggleError({
        ...error,
        email: false
      });
    }
    changeValue({
      ...values,
      [name]: value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (!validateEmail(values.email)) {
      toggleError({
        ...error,
        email: true
      });
      return false;
    }
    setSubmitting(true);
    toggleError({
      ...error,
      submission: false
    });
    try {
      await axios.post(`${baseURL}/api/sendEmail`, {
        name: values.name,
        to: values.email,
        listingAddress: address,
        url
      })
      setTimeout(() => setSubmitted(true), 1000);
    } catch {
      toggleError({
        ...error,
        submission: true
      });
      setSubmitting(false);
      return false;
    }
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Typography variant="h6" gutterBottom className={classes.formTitle}>
        A link to your marketing plan will be emailed to you.
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            name="name"
            type="text"
            value={values["name"]}
            label="Your Name"
            onChange={handleChange}
            error={error["name"] && touched["name"]}
            helperText={error["name"] && touched["name"] && 'Invalid Name'}
            onBlur={() => setTouched({
              ...touched,
              name: true
            })}
            style={{ width: '100%', marginBottom: 8 }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            name="email"
            type="text"
            value={values["email"]}
            onChange={handleChange}
            label="Email"
            error={error["email"] && touched["email"]}
            helperText={error["email"] && touched["email"] && 'Please Enter a valid email'}
            onBlur={() => setTouched({
              ...touched,
              email: true
            })}
            style={{ width: '100%', marginBottom: 8 }}
          />
        </Grid>
      </Grid>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.formButton}
        disabled={isSubmitting}
      >
        { !isSubmitting ? 'Submit' : <CircularProgress size={24} className={classes.buttonProgress} /> }
      </Button>
      {
        error.submission && <Typography variant="body1" className={classes.error}>Error sending plan. Please try again later.</Typography>
      }
    </form>
  );
};

export default Form;
