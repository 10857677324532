import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import useStyles from './styles';
import house from './images/luxe-house.jpeg';

const Launch = () => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Typography variant="h4">Launch Your Campaign Effortlessly</Typography>
      <Grid container className={classes.container}>
        <Grid item xs={12} sm={4}>
          <img className={classes.launchImage} src={house} alt="property" />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography variant="body1" className={classes.text}>To launch the perfect listing campaign, schedule a call with the Luxury Marketing specialists at LUXVT today.</Typography>
          <Typography variant="body1" className={classes.text}>We'll help you:</Typography>
          <ul>
            <Typography variant="body1"><li>Deploy world class advertising</li></Typography>
            <Typography variant="body1"><li>Wow sellers with luxe multimedia</li></Typography>
            <Typography variant="body1"><li>Avoid risk with pay-at-close options</li></Typography>
          </ul>
        </Grid>
      </Grid>
      <div className={classes.block}>
        <a href="https://luxvt.appointlet.com/s/luxvt-listing-planner-launch" target="_blank" rel="noopener noreferrer">
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Schedule A Call
          </Button>
        </a>
      </div>
    </Paper>
  )
};

export default Launch;
