import { makeStyles } from '@material-ui/core/styles';
export default makeStyles(theme => ({
    button: {
      float: 'right',
      clear: 'left',
      fontSize: 12,
      lineHeight: '1em',
      background: 'none',
      marginLeft: theme.spacing(1),
      padding: '6px 8px',
      color: theme.palette.primary.main,
      border: `2px solid ${theme.palette.primary.main}`,
      '& svg': {
        marginRight: 4,
        fontSize: 16
      },
      '&:hover': {
        background: 'none'
      }
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& div': {
        outline: 'none !important'
      }
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4, 5, 5),
      maxWidth: '92vw',
      position: 'relative'
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start'
    },  
    formTitle: {
      marginBottom: theme.spacing(1),
      textAlign: 'center'
    },
    formButton: {
      alignSelf: 'flex-end',
      marginTop: theme.spacing(2),
      width: '100%'
    },
    closeButton: {
      position: 'absolute',
      right: 4,
      top: 4,
      padding: 4,
      minWidth: 0,
      '& svg': {
        fontSize: 20
      }
    },
    error: {
      color: theme.palette.error.main,
      marginTop: theme.spacing(1)
    },
    launchSection: {
      '& > h4': {
        textAlign: 'center',
        fontWeight: 400,
        [theme.breakpoints.down(600)]: {
          fontSize: 18
        }
      }
    },
    container: {
      maxWidth: 560,
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& > div': {
        padding: `0px ${theme.spacing(2)}px`
      },
      '& ul li': {
        [theme.breakpoints.down(600)]: {
          fontSize: 14
        }
      },
      [theme.breakpoints.down(600)]: {
        flexDirection: 'column',
        '& > div': {
          padding: 0
        }
      }
    },
    block: {
      marginTop: theme.spacing(2),
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      '& > a': {
        width: '100%',
        maxWidth: 560,
        textDecoration: 'none'
      }
    },
    launchImage: {
      width: '100%',
      maxWidth: 140
    },
    text: {
      margin: `${theme.spacing(1)}px 0px`,
      [theme.breakpoints.down(600)]: {
        fontSize: 14
      }
    },
    launchButton: {
      width: '100%',
      maxWidth: 560,
      color: 'white',
      fontSize: 18,
      fontWeight: 400
    }
}));
