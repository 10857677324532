import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { InputField, SelectField, NumberFormatField } from '../../FormFields';
import useStyles from './styles';

const propertyTypes = [
  {
    value: 'singleFamilyHome',
    label: 'Single Family Home'
  },
  {
    value: 'condo',
    label: 'Condo'
  },
  {
    value: 'land',
    label: 'Land'
  }
];

const furnishedOptions = [
  {
    value: true,
    label: 'Furnished'
  },
  {
    value: false,
    label: 'Unfurnished'
  }
];

const FormStep1 = ({
  formField: {
    streetAddress,
    zipCode,
    price,
    propertyType,
    squareFeet,
    isFurnished
  }
}) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h6" gutterBottom className={classes.formTitle}>
        Basic Information
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputField name={streetAddress.name} label={streetAddress.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField name={zipCode.name} label={zipCode.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <NumberFormatField name={price.name} label={price.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <SelectField
            name={propertyType.name}
            label={propertyType.label}
            data={propertyTypes}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <SelectField
            name={isFurnished.name}
            label={isFurnished.label}
            data={furnishedOptions}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FormStep1;