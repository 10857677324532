import React, { useState } from 'react';
import { Typography, Button, Grid } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import CloseIcon from '@material-ui/icons/Close';
import { useSpring, animated } from 'react-spring/web.cjs'; // web.cjs is required for IE 11 support
import Form from './Form';
import useStyles from './styles';
import house from './images/luxe-house.jpeg';

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});


const Share = ({ address, url }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [launchOpen, setLaunchOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    setLaunchOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    setLaunchOpen(false);
    setTimeout(() => setSubmitted(false), 600);
  };

  const handleLaunchOpen = () => {
    setLaunchOpen(true);
    setOpen(false);
  }

  const handleLaunchClose = () => {
    setLaunchOpen(false);
    setOpen(false);
  }

  return (
    <>
    <Button 
      variant="contained"
      color="primary"
      className={classes.button}
      onClick={handleOpen}
    >
      Share/Save
    </Button>
    <Button 
      variant="contained"
      color="primary"
      className={classes.button}
      onClick={handleLaunchOpen}
    >
      Launch
    </Button>
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <>
            <Button
              className={classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </Button>
            {
              !submitted ? (
                <Form  address={address} setSubmitted={setSubmitted} url={url} />
              ) : (
                <Typography variant="h6">Your plan has been sent!</Typography>
              )
            }
          </>
        </div>
      </Fade>
    </Modal>
    {/* Launch Modal */}
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      className={classes.modal}
      open={launchOpen}
      onClose={handleLaunchClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      >
        <Fade in={launchOpen}>
          <div className={classes.paper}>
            <>
              <Button
                className={classes.closeButton}
                onClick={handleClose}
              >
                <CloseIcon />
              </Button>
              <div className={classes.launchSection}>
                <Typography variant="h4">Launch Your Campaign Effortlessly</Typography>
                <Grid container className={classes.container}>
                  <Grid item xs={12} sm={4}>
                    <img className={classes.launchImage} src={house} alt="property" />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body1" className={classes.text}>To launch the perfect listing campaign, schedule a call with the Luxury Marketing specialists at LUXVT today.</Typography>
                    <Typography variant="body1" className={classes.text}>We'll help you:</Typography>
                    <ul className={classes.launchList}>
                      <Typography variant="body1"><li>Deploy world class advertising</li></Typography>
                      <Typography variant="body1"><li>Wow sellers with luxe multimedia</li></Typography>
                      <Typography variant="body1"><li>Avoid risk with pay-at-close options</li></Typography>
                    </ul>
                  </Grid>
                </Grid>
                <div className={classes.block}>
                  <a href="https://luxvt.appointlet.com/s/luxvt-listing-planner-launch" target="_blank" rel="noopener noreferrer">
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.launchButton}
                    >
                      Schedule A Call
                    </Button>
                  </a>
                </div>
              </div>
            </>
          </div>
        </Fade>
      </Modal>
    </>
  )
};

export default Share;
