import React, { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PlanItemList from './PlanItemList';
import TipItemList from './TipItemList';
import Launch from '../Launch';
import {
  data, 
  VISUALS,
  ADVERTISING,
  MATERIALS
} from './data';
import useStyles from './styles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid container>
          {children}
        </Grid>
      )}
    </div>
  );
}

const Plan = ({ name, tier, ...rest }) => {
  const theme = useTheme();

  console.log('THEME:', theme);
  
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const values = ['1. Visuals', '2. Advertising', '3. Materials', '4. Tips For You', '5. Launch'];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  return (
    <>
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            className={classes.tabs}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            centered
          >
            <Tab label="Visuals" {...a11yProps(0)} className={classes.tab} />
            <Tab label="Advertising" {...a11yProps(1)} className={classes.tab} />
            <Tab label="Materials" {...a11yProps(2)} className={classes.tab} />
            <Tab label="Tips For You" {...a11yProps(3)} className={classes.tab} />
            <Tab label="Launch" {...a11yProps(4)}className={classes.tab} />
          </Tabs>
          {/* <Tabs
            value={value}
            onChange={handleChange}
            className={classes.tabScroll}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Visuals" {...a11yProps(0)} className={classes.tab} />
            <Tab label="Advertising" {...a11yProps(1)} className={classes.tab} />
            <Tab label="Materials" {...a11yProps(2)} className={classes.tab} />
            <Tab label="Tips For You" {...a11yProps(3)} className={classes.tab} />
            <Tab label="Launch" {...a11yProps(4)}className={classes.tab} />
          </Tabs> */}
          <div className={classes.mobileSelector}>
          { value > 0 ? (
            <button type="button" onClick={() => setValue(value - 1)}><PlayArrowIcon /></button> 
            ) : (
            <button type="button" className={classes.buttonArrowDisabled}><PlayArrowIcon /></button>
            )
          }
            <div className={classes.selectedSection}>
              <Typography variant="body1">{values[value]}</Typography>
            </div>
          { value < 4 ? (
            <button type="button" onClick={() => setValue(value + 1)}><PlayArrowIcon /></button> 
            ) : (
            <button type="button" className={classes.buttonArrowDisabled}><PlayArrowIcon /></button>
            )
          }
          </div>
        </AppBar>
        <TabPanel value={value} index={0}>
          <PlanItemList data={data} type={VISUALS} planName={name} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PlanItemList data={data} type={ADVERTISING} planName={name} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <PlanItemList data={data} type={MATERIALS} planName={name} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <TipItemList data={data} {...rest} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Launch />
        </TabPanel>
      </div>
    </>
  );
};

export default Plan;