import { makeStyles } from '@material-ui/core/styles';
export default makeStyles(theme => ({
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    '&:first-child': {
      marginTop: 0
    },
    [theme.breakpoints.down(540)]: {
      padding: theme.spacing(1)
    }
  },
  photoOuter: {
    width: 128,
    height: 96,
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing(2),
    overflow: 'hidden',
    display: 'none',
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    '& > img': {
      maxWidth: 175
    }
  },
  accordionOuter: {
    flexGrow: 1,
    flexShrink: 50,
    width: '100%'
  },  
  accordion: {
    marginTop: '0 !important'
  },
  accordionSummary: {
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    [theme.breakpoints.down(540)]: {
      padding: `0px ${theme.spacing(1)}px 0px`
    }
  },
  accordionTop: {
    width: '100%',
    minHeight: 96 - 24,
    padding: '12px 8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& > h4': {
      fontWeight: 400
    }
  },
  itemDescription: {
    '& > span:nth-child(1)': {
      color: theme.palette.secondary.main,
      fontWeight: 'bold'
    },
    '& > span:nth-child(2)': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      textDecoration: 'underline',
      transitionProperty: 'color',
      transitionTimingFunction: theme.transitions.easing.easeInOut,
      transitionDuration: theme.transitions.duration.shortest,
      '&:hover': {
        color: theme.palette.primary[300]
      }
    }
  },  
  recommendedFlag: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 8px',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 14,
    borderTopRightRadius: theme.shape.borderRadius,
    [theme.breakpoints.down(540)]: {
      padding: '1px 8px'
    }
  },
  optionalFlag: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: theme.palette.info.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 8px',
    color: 'white',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 14,
    borderTopRightRadius: theme.shape.borderRadius,
    [theme.breakpoints.down(540)]: {
      padding: '1px 8px'
    }
  },
  details: {
    padding: `0px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    [theme.breakpoints.down(540)]: {
      padding: `0px ${theme.spacing(1)}px ${theme.spacing(2)}px`
    }
  },
  accordionBottom: {
    width: '100%',
    padding: '0px 8px',
    '& > ul': {
      margin: 0,
      marginLeft: theme.spacing(2) + 1,
      listStylePosition: 'oustide',
      padding: 0,
    },
    '& > ul li': {
      marginBottom: theme.spacing(1)
    },
    '& > h5:nth-child(1)': {
      marginTop: 0,
      marginBottom: theme.spacing(2),
      fontWeight: 600,
      fontSize: 18
    }
  },
  resourcesAndVendors: {
    '& > h5': {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      fontWeight: 600,
      fontSize: 18
    },
    [theme.breakpoints.down(540)]: {
      '& > h5': {
        fontSize: 16
      }
    }
  },
  resource: {
    marginBottom: theme.spacing(1),
    '& a': {
      color: theme.palette.primary.main,
      transitionProperty: 'color',
      transitionTimingFunction: theme.transitions.easing.easeInOut,
      transitionDuration: theme.transitions.duration.shortest
    },
    '& a:hover': {
      color: theme.palette.primary[300]
    },
    [theme.breakpoints.down(540)]: {
      fontSize: 14
    }
  }
}));