import * as Yup from 'yup';
import formModel from './formModel';
import isEmpty from 'lodash/fp/isEmpty';
const {
  formField: {
    streetAddress,
    zipCode,
    price,
    propertyType,
    isFurnished,
    sellerProfile,
    propertyHighlights,
    agentObjectives
  }
} = formModel;


const validationSchema = [
  Yup.object().shape({
    [streetAddress.name]: Yup.string(),
    [zipCode.name]: Yup.string(),
    [price.name]: Yup.string().required(`${price.requiredErrorMsg}`).test('number', `${price.invalidErrorMsg}`, val => !isEmpty(val) && !isEmpty(val.split(',').join('').match(/^[0-9]+$/)) && !isNaN(parseInt(val, 10)) && (parseInt(val, 10) > 10000)),
    [propertyType.name]: Yup.string(),
    [isFurnished.name]: Yup.bool()
  }),
  Yup.object().shape({
  }),
  Yup.object().shape({
    [sellerProfile.name]: Yup.string(),
    [propertyHighlights.name]: Yup.string(),
    [agentObjectives.name]: Yup.string()
  }),

];

export default validationSchema;
