import { makeStyles } from '@material-ui/core/styles';
export default makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[200],
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
    marginTop: theme.spacing(4),
    width: '100%',
    '& > h4': {
      textAlign: 'center',
      fontWeight: 400
    }
  },
  container: {
    maxWidth: 560,
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > div': {
      padding: `0px ${theme.spacing(2)}px`
    },
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column',
      '& > div': {
        padding: 0
      }
    }
  },
  photoOuter: {
    width: '100%',
    maxWidth: 320,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    height: 180,
    '& > img': {
      maxWidth: 320
    },
    [theme.breakpoints.down(600)]: {
      marginBottom: theme.spacing(2)
    }
  },
  launchImage: {
    width: '100%',
    maxWidth: 320
  },  
  text: {
    margin: `${theme.spacing(1)}px 0px`
  },
  block: {
    marginTop: theme.spacing(2),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& > a': {
      width: '100%',
      maxWidth: 560,
      textDecoration: 'none'
    }
  },
  button: {
    width: '100%',
    maxWidth: 560,
    color: 'white',
    fontSize: 18,
    fontWeight: 400
  }
}));